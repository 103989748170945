* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: black;
    font-size: 15px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #f1c5be;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .top-nav-bar-container {
    position: sticky;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 4em;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
  }
  .nav-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.746);
    z-index: 100;
  }
  .nav-data-container {
    position: relative;
    width: 70%;
    height: 100%;
    background: #cccccc;
    display: flex;
    flex-direction: column;
    padding: 1em;
    row-gap: 1em;
    padding-top: 1.5em;
    left: -70%;
    animation: fadeInFromLeft 0.3s ease forwards;
  }
  
  @media only screen and (min-width: 600px) {
    .nav-data-container {
      width: 500px;
    }
  }
  @keyframes fadeInFromLeft {
    from {
      left: -70%;
    }
    to {
      left: 0;
    }
  }
  .nav-sub-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }
  .nav-sub-container-para {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: xx-large;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .nav-strong-text {
    font-weight: bold;
  }
  .flex-row-design {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }
.home-cards {
  position: relative;
}