.icon-style {
  color: #808080;
}
.img-icon {
  height: 20px;
  width: 20px;
  filter: grayscale(100%);
  filter: opacity(0.4) drop-shadow(0 0 0 gray);
}
.img-Logo {
  height: 40px;
  width: 40px;
  margin-top: 3px;
}
