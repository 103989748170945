.specific {
  font-family: "AtlasGrotesk";

  font-weight: 400;

  font-size: "14px";

  line-height: normal;
  color: rgb(30, 25, 25);
  font-style: normal;
}

.details {
  font-family: "Montserrat Semibold";

  font-weight: "600px";

  font-size: "14px";

  line-height: "22px";

  color: rgb(90, 90, 90);

  font-style: normal;
}

.bottom-nav-bar-container-1 {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
  z-index: 2;
  background: rgb(75, 78, 75);
  color: #fff;
  
}
.bottom-nav-bar-menu-container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5em;
  z-index: 10;
  width: 100%;
  background: #db4b32;
}
.bottom-nav-bar-slider-container {
  position: relative;
  top: -1.5em;
  transition: 0.5s all ease;
}
.slider-opens {
  position: relative;
  transition: 0.5s all ease;
  top: -15.5em;
}
.slider-icon-down {
  position: absolute;
  left: 50%;
  display: grid;
  font-size: 24px !important;
  place-items: center;
  background: #7a250e;
  border-radius: 99vh;
  top: -1.2em;
  color: rgba(255, 255, 255, 0.767);
  transition: 0.4s ease all;
  transform: translateX(-50%) rotate(180deg);
}

.bottom-nav-menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 0.5em;
  flex-direction: column;
  color: rgba(27, 3, 3, 0.733);
}
.bottom-nav-slider-main-container {
  position: absolute;
  width: 100%;
  height: 5em;
  border-top: 1.5em solid #7a250e;
}
.bottom-nav-slider-top-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  font-size: 24px !important;
  place-items: center;
  background: #7a250e;
  border-radius: 99vh;
  top: -1.2em;
  color: rgba(255, 255, 255, 0.767);
  transition: 0.4s ease all;
}
.bottom-nav-image-slider-box {
  position: absolute;
  width: 100%;
  max-height: 20vh;
  background-color: #db4b32;
}
.show-image {
  position: relative;
  width: 100%;
  max-height: 20vh;
}

.bottom-nav-images {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bottom-nav-button-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 180%;
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 0) 43%,
    rgba(0, 0, 0, 0.9) 98%
  );
}
.slider-buttons {
  color: #fff;
}
.slider-buttons:active {
  background: rgba(255, 255, 255, 0.205);
  border-radius: 99vh;
}
.bottom-nav-active {
  color: rgb(0, 0, 0);
  font-weight: bold;
}
