.Row {
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: 10px; /*Optional*/
}
.Column {
    display: table-cell;
    background-color: red;
    text-align: center; /*Optional*/
    justify-content: center;
}
